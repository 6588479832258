<template>
  <b-modal
    id="modal-pengajuan"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header
        class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"
      >
        <b-row>
          <b-col cols="3" class="d-flex align-items-center">
            <div style="cursor: pointer" @click="directPage">
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
                >Kembali</span
              >
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              {{
                editUuid == null ? "Pengajuan ke Pemasok" : "Gabung ke Pemasok"
              }}
            </h1>
          </b-col>
          <b-col cols="3" class="text-right" v-if="editUuid === null">
            <b-button class="px-3" @click="createItem()">
              <b-spinner small v-if="isLoading" />
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col cols="12" sm="12" md="7">
            <b-row>
              <b-col cols="12" md="6" class="mb-2 custom__form--input">
                <label for="v-nama-gudang">Pilih Pemasok</label>
                <b-form-group>
                  <b-form-input
                    v-if="editUuid !== null"
                    id="v-nama-gudang"
                    v-model="formPayload.target_merchant"
                    type="text"
                    placeholder="Nama"
                    class="custom__input"
                    disabled
                  />
                  <v-select
                    v-else
                    v-model="formPayload.target_merchant_id"
                    label="name"
                    :options="listMerchant"
                    placeholder="Pemasok"
                    :reduce="(merchant) => merchant.id"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mb-2 custom__form--input">
                <label for="v-nama-toko">Nama Toko </label>
                <b-form-group>
                  <b-form-input
                    id="v-nama-toko"
                    v-model="formPayload.store_name"
                    type="text"
                    placeholder="Nama Toko"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="editUuid !== null">
              <b-col cols="12" md="6" class="mb-2 custom__form--input">
                <label for="v-payment">Metode Bayar</label>
                <b-form-group>
                  <b-form-input
                    id="v-payment"
                    v-model="formPayload.payment_type"
                    type="text"
                    placeholder="Metode Bayar"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mb-2 custom__form--input">
                <label for="v-limit">Limit </label>
                <b-form-group>
                  <b-form-input
                    id="v-limit"
                    v-model="formPayload.limit"
                    type="text"
                    placeholder="Limit"
                    class="custom__input"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" class="mb-2 custom__form--input">
                <label for="v-nama-owner">Nama Pemilik Toko </label>
                <b-form-group>
                  <b-form-input
                    id="v-nama-owner"
                    v-model="formPayload.owner_name"
                    type="text"
                    placeholder="Nama Owner"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mb-2 custom__form--input">
                <label for="v-phone">No. Telepon Pemilik </label>
                <b-row>
                  <b-col cols="5" class="pr-0">
                    <v-select
                      v-model="formPayload.selectedCountry"
                      :label="currentLabel"
                      :options="countryListData"
                      :clearable="false"
                      @keyup.stop.native="searchCountry"
                    >
                      <template
                        #selected-option="{ country_flag, dial_code_preview }"
                      >
                        <div class="d-flex align-items-center" style="gap: 6px">
                          <img
                            :src="country_flag"
                            :alt="dial_code_preview"
                            style="width: 24px; height: 24px"
                          />
                          <p class="size12 mb-0">({{ dial_code_preview }})</p>
                        </div>
                      </template>
                      <template #option="{ country_flag, dial_code_preview }">
                        <div class="d-flex align-items-center" style="gap: 8px">
                          <img
                            :src="country_flag"
                            :alt="dial_code_preview"
                            style="width: 24px; height: 24px"
                          />
                          <p class="mb-0">{{ dial_code_preview }}</p>
                        </div>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col cols="7">
                    <b-form-group>
                      <b-form-input
                        id="v-phone"
                        v-model="formattedPhone"
                        type="tel"
                        placeholder="Telpon Cabang"
                        class="custom__input"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-3 custom__form--input">
                <label for="v-address">Alamat</label>
                <b-form-group>
                  <b-form-textarea
                    id="v-address"
                    v-model="formPayload.address"
                    rows="5"
                    placeholder="Alamat"
                    class="custom__textarea"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="4" class="custom__form--input">
                <label class="mb-1">Foto KTP Pemilik</label>
                <UploadPhoto
                  v-if="photo_ktp == ''"
                  :label-for="'upload_photo'"
                  :style-name="'width:100%; height:140px; margin: 0 auto; background-size:cover;'"
                  :photo-url-parent="'asdasd'"
                  @photo="getPhoto($event, 'photo_ktp')"
                />
                <div v-else>
                  <div
                    class="box__upload--photo mx-auto"
                    :style="`background-image: url(${photo_ktp});width: 100%;height: 140px; margin: 0 auto; background-size: cover;`"
                    @click="clearPhoto('photo_ktp')"
                  >
                    <b-avatar class="floating__close--button">
                      <feather-icon class="text-danger" icon="XIcon" />
                    </b-avatar>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="4" class="custom__form--input">
                <label class="mb-1">Foto NPWP</label>
                <UploadPhoto
                  v-if="photo_npwp == ''"
                  :label-for="'upload_photo'"
                  :style-name="'width:100%; height:140px; margin: 0 auto; background-size:cover;'"
                  :photo-url-parent="'asdasd'"
                  @photo="getPhoto($event, 'photo_npwp')"
                />
                <div v-else>
                  <div
                    class="box__upload--photo mx-auto"
                    :style="`background-image: url(${photo_npwp});width: 100%;height: 140px; margin: 0 auto; background-size: cover;`"
                    @click="clearPhoto('photo_npwp')"
                  >
                    <b-avatar class="floating__close--button">
                      <feather-icon class="text-danger" icon="XIcon" />
                    </b-avatar>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="4" class="custom__form--input">
                <label class="mb-1">Foto Toko</label>
                <UploadPhoto
                  v-if="photo_shop == ''"
                  :label-for="'upload_photo'"
                  :style-name="'width:100%; height:140px; margin: 0 auto; background-size:cover;'"
                  :photo-url-parent="'asdasd'"
                  @photo="getPhoto($event, 'photo_shop')"
                />
                <div v-else>
                  <div
                    class="box__upload--photo mx-auto"
                    :style="`background-image: url(${photo_shop});width: 100%;height: 140px; margin: 0 auto; background-size: cover;`"
                    @click="clearPhoto('photo_shop')"
                  >
                    <b-avatar class="floating__close--button">
                      <feather-icon class="text-danger" icon="XIcon" />
                    </b-avatar>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BAvatar,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
// import { VueTelInput } from "vue-tel-input";

import UploadPhoto from "@/components/UploadPhoto.vue";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BContainer,
    UploadPhoto,
    BAvatar,
    BSpinner,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormRadio,
    // VueTelInput,
  },
  props: {
    formData: {
      type: "",
    },
    createItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    countryList: {
      type: Array,
    },
    listMerchant: {
      type: Array,
    },
    searchQuery: {
      type: String,
    },
    result: {
      type: Object,
    },
    photoKtp: {
      type: String,
    },
    photoNpwp: {
      type: String,
    },
    photoShop: {
      type: String,
    },
    searchCountry: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editUuid: null,
      formPayload: {
        target_merchant_id: null,
        store_name: "",
        owner_name: "",
        phone_number: "",
        phone_number_country: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        address: "",
        ktp_photo: "",
        npwp_photo: "",
        shop_photo: "",
        payment_type: null,
        limit: null,
      },
      isDeleting: false,
      photo_ktp: "",
      photo_npwp: "",
      photo_shop: "",
      userListData: [],
      countryListData: [],
      listMerchantData: [],
      searchQueryData: "",
    };
  },
  computed: {
    currentLabel() {
      return /^\d+$/.test(this.searchQueryData)
        ? "dial_code_value"
        : "country_name";
    },
    dialCode() {
      const selectedCountry = this.countryListData.find(
        (country) =>
          country.country_code ===
          this.formPayload.selectedCountry?.country_code
      );
      return selectedCountry ? selectedCountry.dial_code_value : "62";
    },
    formattedPhone: {
      get() {
        if (!this.formPayload.phone_number) {
          return "";
        }
        const regex = new RegExp(`^${this.dialCode}`);
        return this.formPayload.phone_number.replace(regex, "");
      },
      set(value) {
        if (value.startsWith("0")) {
          value = value.substring(1);
        }
        this.formPayload.phone_number = this.dialCode + value;
      },
    },
  },

  watch: {
    "formPayload.selectedCountry"(newVal, oldVal) {
      const oldDialCode = oldVal ? oldVal.dial_code_value : "";
      const newDialCode = newVal.dial_code_value;
      if (this.formPayload.phone_number.startsWith(oldDialCode)) {
        this.formPayload.phone_number =
          newDialCode + this.formPayload.phone_number.replace(oldDialCode, "");
      }
    },
    editId(value) {
      this.editUuid = value;
    },
    formData(value) {
      this.formPayload = value;
    },
    countryList(value) {
      this.countryListData = value;
    },
    listMerchant(value) {
      this.listMerchantData = value;
    },
    searchQuery(value) {
      this.searchQueryData = value;
    },
    photoKtp(value) {
      this.photo_ktp = value;
    },
    photoNpwp(value) {
      this.photo_npwp = value;
    },
    photoShop(value) {
      this.photo_shop = value;
    },
    "formPayload.phone_number"(newVal) {
      this.formPayload.phone_number = newVal.replace(/[^0-9]/g, "");
    },
    formPayload: {
      handler(value) {
        this.$emit("getPayload", value);
      },
      deep: true,
    },
    messages: {
      handler(value) {
        this.messagesChild = value;
      },
      deep: true,
    },
  },
  mounted() {
    // this.getUsers();
  },
  methods: {
    clearPhoto(event) {
      if (event === "photo_ktp") {
        this.photo_ktp = "";
        this.formPayload.ktp_photo = "";
      } else if (event === "photo_npwp") {
        this.photo_npwp = "";
        this.formPayload.npwp_photo = "";
      } else {
        this.photo_shop = "";
        this.formPayload.shop_photo = "";
      }
    },
    getPhoto(value, event) {
      if (event === "photo_ktp") {
        this.formPayload.ktp_photo = value.photo;
      } else if (event === "photo_npwp") {
        this.formPayload.npwp_photo = value.photo;
      } else {
        this.formPayload.shop_photo = value.photo;
      }
    },
    directPage() {
      // this.cleanUpForm();
      this.$bvModal.hide("modal-pengajuan");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.box__upload--photo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eff1f5;
  border: 1px dashed #7a7f94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 195px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<!-- <style src="vue-tel-input/dist/vue-tel-input.css"></style> -->
